<template>
  <div id="addUserInternalModal">
    <CModal
      :show.sync="showModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <CInput
          label="Name"
          horizontal
          placeholder="Enter name..."
          v-model="inputName"
          required
          was-validated
        />
        <CInput
          type="email"
          autocomplete="email"
          label="Email"
          horizontal
          placeholder="Enter Email..."
          v-model="inputEmail"
          required
          was-validated
        />
        <div class="form-row">
          <CCol tag="label" sm="3" class="col-form-label">Permissions</CCol>
          <CCol sm="9">
            <template v-for="(permission, index) in permissionList">
              <div class="form-row form-check" :key="index">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="permission.value"
                  :value="permission.value"
                  v-model="checkedPermissions"
                />
                <label
                  :key="index"
                  :for="permission.value"
                  class="form-check-label"
                  >{{ permission.name }}</label
                >
              </div>
            </template>
            <!-- <span>Checked: {{ checkedPermissions }}</span> -->
          </CCol>
        </div>
      </CForm>
      <template #header>
        <h5 class="modal-title">Add User Internal</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton @click="closeModal" color="secondary">Cancel</CButton>
        <CButton @click="onAddUser" color="primary">Add</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { PermissionList } from '@/utils/utils';

export default {
  components: {},
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      inputName: '',
      inputEmail: '',
      permissions: ['provider'],
      checkedPermissions: [],
    };
  },
  computed: {
    permissionList: function () {
      return PermissionList();
    },
  },
  methods: {
    closeModal() {
      this.inputName = '';
      this.inputEmail = '';
      this.checkedPermissions = [];
      this.$emit('close-modal');
    },
    onAddUser(e) {
      if (this.inputName && this.inputEmail) {
        this.$emit('add-user-internal', {
          name: this.inputName,
          email: this.inputEmail,
          permissions: this.checkedPermissions,
        });
      }
      e.preventDefault();
    },
  },
  name: 'AddUserInternalModal',
};
</script>
