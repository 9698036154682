<template>
  <CContainer fluid>
    <CCard>
      <CCardHeader>
        <CRow class="justify-content-between px-3">
          <h5>UserInternals</h5>
          <div class="d-flex">
            <CSelect
              class="mx-2 my-0"
              :value.sync="filterStatusSelected"
              :options="options"
              @change="doFilterUsers"
            />
            <CInput
              style="width: 400px"
              class="px-0 my-0"
              placeholder="email"
              :value.sync="filterEmailInput"
            >
              <template #append>
                <CButton color="primary" @click="doFilterUsers">Search</CButton>
                <CButton
                  class="ml-2"
                  size="sm"
                  color="success"
                  @click="isShowAddUserInternalModalComponent = true"
                  >Add User</CButton
                >
              </template>
            </CInput>
          </div>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          hover
          striped
          border
          :items="userinternals"
          :fields="tableFields"
          :items-per-page="25"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <td slot="id" slot-scope="{ item }">
            <CLink :href="'#/user-internals/' + item.id">
              {{ item.id }}
            </CLink>
          </td>

          <td slot="status" slot-scope="{ item }">
            <CBadge :color="getUserBadge(item.status)">
              {{ item.status }}
            </CBadge>
          </td>

          <td slot="permissions" slot-scope="{ item }">
            <template v-for="permission in item.permissions">
              <CBadge :color="`secondary`" class="mr-1" :key="permission">
                {{ permission }}
              </CBadge>
            </template>
          </td>
          <td slot="last_login" slot-scope="{ item }">
            {{ moment(item.last_login).fromNow() }}
          </td>
          <td slot="created_at" slot-scope="{ item }">
            {{ item.created_at | moment(datetimeFormat) }}
          </td>
        </CDataTable>
      </CCardBody>
    </CCard>
    <AddUserInternalModal
      @add-user-internal="onAddUserInternal"
      @close-modal="onCloseAddUserInternalModalComponent"
      :showModal="isShowAddUserInternalModalComponent"
    />
  </CContainer>
</template>

<script>
import { ACTION_FETCH_USER_INTERNALS } from '@/store/actions.type';
import AddUserInternalModal from '@/components/modals/AddUserInternalModal';
import UserInternalProxy from '@/proxies/userinternal.proxy';
import { Common } from '@/constants';

import { mapGetters } from 'vuex';
import { UserBadge } from '@/utils/utils';

export default {
  name: 'UserInternals',
  components: {
    AddUserInternalModal,
  },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      tableFields: [
        { key: 'id', _style: 'width: 20px' },
        { key: 'status', _style: 'width: 40px' },
        { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
        { key: 'email' },
        { key: 'role' },
        { key: 'permissions' },
        { key: 'last_login', label: 'LastLogin' },
        { key: 'created_at', label: 'CreatedAt' },
      ],
      activePage: 1,
      options: [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
        { value: 'banned', label: 'Banned' },
      ],
      filterStatusSelected: 'all',
      filterEmailInput: '',
      isShowAddUserInternalModalComponent: false,
    };
  },
  mounted() {
    this.$store.dispatch(ACTION_FETCH_USER_INTERNALS, {});
  },
  computed: {
    ...mapGetters(['userinternals']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getUserBadge(status) {
      return UserBadge(status);
    },

    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    doFilterUsers() {
      this.$store.dispatch(ACTION_FETCH_USER_INTERNALS, {
        status:
          this.filterStatusSelected == 'all' ? '' : this.filterStatusSelected,
        email: this.filterEmailInput,
      });
    },
    onCloseAddUserInternalModalComponent() {
      this.isShowAddUserInternalModalComponent = false;
    },
    onAddUserInternal(data) {
      let { email } = data;
      UserInternalProxy.addUser(data).then(() => {
        this.isShowAddUserInternalModalComponent = false;
        this.$toast.success(`Add user ${email} successfully`, {
          duration: 5000,
        });

        this.$store.dispatch(ACTION_FETCH_USER_INTERNALS, {});
      });
    },
  },
};
</script>
